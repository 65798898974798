<template>
  <v-menu
    bottom
    left
    offset-y
    :max-width="480"
    content-class="clone-menu mt-2">
    <template #activator="{ on }">
      <CyButton
        prepend-icon="link"
        variant="secondary"
        theme="primary"
        v-on="on">
        <v-icon
          class="mr-1"
          :size="14">
          fas fa-terminal
        </v-icon>
        <span v-text="$t('stacks.clone')"/>
      </CyButton>
    </template>
    <div
      class="pa-4"
      @click.stop>
      <p>
        {{ $t('stacks.cloneInstructions') }}
        <a
          class="cy-link"
          rel="noopener"
          :href="$docLinks.catalogRepositories.create"
          target="_blank">
          {{ $t('learnMore') }}
        </a>
      </p>
      <CyTooltip
        :max-width="240"
        left>
        <template #activator="{ on }">
          <div
            v-clipboard:copy="cloneCommand"
            v-clipboard:success="() => SHOW_ALERT({ type: 'info', content: $t('commandCopied') })"
            data-cy="clone-command"
            class="command-snippet px-4 py-2 my-4"
            v-on="on"
            v-text="cloneCommand"/>
        </template>
        {{ $t('clickToCopy') }}
      </CyTooltip>
      <div
        v-if="catalogRepository.credential_canonical"
        class="h6"
        v-text="$t('Credential')"/>
      <div
        v-if="catalogRepository.credential_canonical"
        class="d-flex align-center mt-2">
        <CyIconCredential
          size="24"
          class="mr-2"
          type="ssh"/>
        <router-link
          class="cy-link"
          :to="{ name: 'credential', params: { credentialCanonical: catalogRepository.credential_canonical } }">
          {{ catalogRepository.credential_canonical }}
        </router-link>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CyWizardStackCloneMenu',
  props: {
    catalogRepository: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cloneCommand () {
      return `git clone --branch ${this.catalogRepository.branch} ${this.catalogRepository.url}`
    },
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
  },
  i18n: {
    messages: {
      en: {
        clickToCopy: 'Click to copy to clipboard',
        commandCopied: 'The command has been copied to your clipboard.',
      },
      es: {
        clickToCopy: 'Haga clic para copiar al portapapeles',
        commandCopied: 'El comando ha sido copiado a su portapapeles.',
      },
      fr: {
        clickToCopy: 'Cliquez pour copier dans le presse-papier',
        commandCopied: 'La commande a été copiée dans votre presse-papiers.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.clone-menu {
  .command-snippet {
    border-radius: 4px;
    background-color: cy-get-color("black");
    color: cy-get-color("white");
    font-family: $font-family-code;
    cursor: pointer;
  }
}
</style>
